<template>
  <div class="col-12">
    <Card>
      <template #title>Busca por servidores</template>
      <template #content>
        <form @submit.prevent="onFilter()">
          <div class="col-12 md:col-6 p-0 md:flex">
            <div class="entrada w-full mr-2">
              <InputText
                id="servidorInput"
                v-model="filters.matriculaCpf.value"
                class="w-full"
                type="text"
                placeholder="CPF, Matrícula ou E-mail"
                autofocus />
            </div>
            <div class="botoes mt-2 md:mt-0 flex justify-content-center">
              <Button
                id="btn-limparBuscarServidor"
                class="p-button-outlined mr-2"
                icon="pi pi-filter-slash"
                label="Limpar"
                @click="clearFilters" />
              <Button
                id="btn-buscarServidor"
                :icon="loading ? 'pi pi-spin pi-spinner' : 'pi pi-search'"
                :label="loading ? 'Carregando...' : 'Buscar'"
                @click="onFilter()" />
            </div>
          </div>
        </form>
        <DataTable
          v-show="exibeTabela"
          class="mt-3"
          :value="servidores"
          stripedRows
          :paginator="true"
          :rows="10"
          :loading="loading"
          :totalRecords="totalRecords"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="rowsPerPageOptions"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} servidores"
          responsiveLayout="scroll">
          <template #empty> Nenhum servidor encontrado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column v-if="$auth.hasRoleGestao()" field="id" header="ID"></Column>
          <Column field="cpf" header="CPF"></Column>
          <Column field="nome" header="Nome"></Column>
          <Column field="matricula" header="Matrícula"></Column>
          <Column field="entidadeNome" header="Entidade"></Column>
          <Column field="vinculoNome" header="Vínculo">
            <template #body="{ data }">
              <span
                v-if="
                  data.vinculoNome === 'PENSIONISTA' ||
                  data.vinculoNome === 'APOSENTADO' ||
                  data.vinculoNome === 'EFETIVO'
                "
                style="color: green">
                {{ data.vinculoNome }}
              </span>
              <span v-else style="color: red">
                {{ data.vinculoNome }}
              </span>
            </template>
          </Column>
          <Column header="Situação">
            <template #body="{ data }">
              <div class="flex align-items-center">
                <Tag
                  v-tooltip.bottom="
                    data.validacao.servidorApto
                      ? 'O servidor está apto a consignar.'
                      : 'O servidor está inapto a consignar pelo motivo(s): ' +
                        exibirMotivosInapto(data.validacao.motivos)
                  "
                  :value="
                    data.validacao.servidorApto
                      ? 'Apto a consignar'
                      : 'Inapto a consignar: ' +
                        exibirMotivosInapto(data.validacao.motivos)
                  "
                  :severity="data.validacao.severity">
                </Tag>
              </div>
            </template>
          </Column>
          <Column header="Ações">
            <template #body="slotProps">
              <Button
                v-tooltip.left="'Detalhes'"
                class="p-button-rounded"
                :icon="loadingDetail ? 'pi pi-spin pi-spinner' : 'pi pi-eye'"
                @click="detalheServidor(slotProps.data)">
              </Button>
            </template>
          </Column>
        </DataTable>
        <Dialog
          modal
          header="Header"
          :style="{ width: '350px' }"
          :visible.sync="deleteServidorDialog">
          <template #header>
            <h3>Excluir Servidor</h3>
          </template>

          Confirma a exclusão de {{ servidor.nome }} ?

          <template #footer>
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteServidorDialog = false" />
            <Button
              label="Sim"
              icon="pi pi-check"
              autofocus
              @click="deleteServidor" />
          </template>
        </Dialog>
      </template>
    </Card>
  </div>
</template>

<script>
import MatriculaService from '@/service/MatriculaService.js'

export default {
  data() {
    return {
      servidores: null,
      servidor: {},
      deleteServidorDialog: false,
      exibeTabela: false,
      loading: false,
      totalRecords: 0,
      parametros: {},
      filters: {
        matriculaCpf: { value: null, matchMode: 'exactly' },
      },
      loadingDetail: false,
    }
  },

  computed: {
    rowsPerPageOptions() {
      if (this.totalRecords < 5) {
        return null
      }
      return [5, 10, 25]
    },
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
  },

  mounted() {
    this.parametros = {
      first: 0,
      page: 0,
      filters: this.filters,
    }
  },

  methods: {
    detalheServidor(data) {
      this.loadingDetail = true
      this.$router.push({ name: 'servidores-margem', params: { id: data.id } })
      this.loadingDetail = false
    },

    onPage(event) {
      this.parametros = event
      this.carregarDadosFiltrados()
    },

    onFilter() {
      if (this.filters.matriculaCpf.value) {
        this.parametros.filters = this.filters
        this.carregarDadosFiltrados()
      } else {
        this.$toast.add({
          severity: 'warn',
          summary: 'Informe o CPF ou a matrícula.',
          life: 10000,
        })
      }
    },

    carregarDadosFiltrados() {
      this.loading = true
      this.matriculaService
        .getMatriculas(this.parametros)
        .then((res) => {
          this.servidores = res
          this.totalRecords = res.length
          this.exibeTabela = true
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    clearFilters() {
      this.filters.matriculaCpf.value = null
      this.servidores = null
      this.exibeTabela = false
    },

    exibirMotivosInapto(arrayMotivos) {
      return arrayMotivos.length > 1 ? arrayMotivos[1] : arrayMotivos[0]
    },
  },
}
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.p-button-black {
  color: #212529 !important;
}
</style>
